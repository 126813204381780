<template>
  <div class="app-container">
    <!-- 搜索栏开始 -->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="申请时间">
        <el-date-picker
          v-model="formInline.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="服务商编号">
        <el-input v-model="formInline.projectId" placeholder="服务商编号"></el-input>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select v-model="formInline.applyState" placeholder="请选择">
          <el-option label="全部" value></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="财务状态">
        <el-select v-model="formInline.financeState" placeholder="请选择">
          <el-option label="全部" value></el-option>
          <el-option label="待处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain round @click="searchData" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 搜索栏结束 -->
    <!-- 表格开始 -->
    <el-table
      border
      :data="tableDataList"
      style="width: 100%;"
      height="700"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="申请编号" width="80">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务商编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.projectId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款单位银行账号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.bankCard }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款单位开户银行">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.bankName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款单位名称">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.accountName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.applyType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.applyTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请的员工编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.userId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.applyState==1?'success':scope.row.applyState==2?'danger':'info'"
            @click="changeState(scope.row)"
          >{{ scope.row.applyStates }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="财务状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.financeState==1?'success':'info'" @click="changeFinanceState(scope.row)">{{ scope.row.financeStates }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->
    <!-- 状态更改开始 -->
    <el-dialog :title="title" :visible.sync="centerDialogVisible" width="20%">
      <div>
        <el-form ref="stateForm" :model="stateForm" label-width="100px" style="margin:0 auto">
          <el-form-item label="审核状态" prop="information" v-if="this.title=='审核状态'">
            <el-select v-model="stateForm.applyState" placeholder="请选择">
              <el-option v-for="(item,i) in applyStateTypeAll" :key="i" :label="item.name" :value="item.value" :disabled="item.value==applyStates?true:false"></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="财务状态" prop="information" v-else>
            <el-select v-model="stateForm.financeState" placeholder="请选择">
              <el-option label="待处理" :value="0" :disabled="financeStates==0?true:false"></el-option>
              <el-option label="已处理" :value="1" :disabled="financeStates==1?true:false"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
       <span slot="footer" class="dialog-footer">
         <el-button @click="centerDialogVisible = false" style="margin-right:20px">取 消</el-button>
         <el-button type="primary" @click="confirmSetState">确 定</el-button>
       </span>
    </el-dialog>
    <!-- 状态更改结束 -->
    <!-- 分页 -->
    <div style="text-align:right;">
      <Paging :totalSize="totalSize" :getMethods="getTableData" />
    </div>
  </div>
</template>

<script>
import Paging from "../../../components/Paging/Paging.vue";
export default {
  components: {
    Paging
  },
  data() {
    return {
      formInline: {
        time: null
      },
      tableDataList: [],
      totalSize: 0,
      stateForm: {}, //状态表单值
      title: "",
      centerDialogVisible:false,//
      setDataObj:{},
      applyStateTypeAll:[//所有审核状态类型
        {name:'待审核',value:0},
        {name:'审核通过',value:1},
        {name:'审核不通过',value:2}
      ],
      applyStates:'',//更改审核状态初始值
      financeStates:'',//更改财务状态初始值
      applyStateValue:true,//审核状态按钮禁止时间
      financeStateValue:true,//财务状态按钮禁止时间
    };
  },
  methods: {
    //获取表格数据
    getTableData(pageData) {
      this.pageDatalist = { ...pageData };
      const { time, projectId, applyState, financeState } = this.formInline;
      let fixedData = {
        projectId,
        applyState,
        financeState
      };
      let datalists = {
        pageConfig: {
          orderType: "desc", //升序、降序
          pageSize: this.pageDatalist.pageSize, //每页大小
          orderField: "id", //排序字段
          pageNum: this.pageDatalist.pageNum //第n页
        },
        baseBean: fixedData
      };
      if (time !== null) {
        datalists.searchBeans = [
          {
            fieldName: "applyTime",
            startValue: time[0] / 1000,
            endValue: time[1] / 1000,
            operator: "BETWEEN"
          }
        ];
      }
      this.$axios
        .post("/operations/queryProjectProfitApply", datalists)
        .then(res => {
          this.loading = false;
          if (res.data.state == 1) {
            this.tableDataList = res.data.projectProfitApplyPage;
            let index =
              (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
            this.tableDataList.map(item => {
              index++;
              item.index = index;
              if (item.applyTime !== undefined) {
                item.applyTime = this.getTime(item.applyTime);
              }
              item.applyType = item.applyType == 1 ? "对公" : "对私";
              item.financeStates =
                item.financeState == 0
                  ? "待处理"
                  : item.financeState == 1
                  ? "已处理"
                  : "";
              item.applyStates =
                item.applyState == 0
                  ? "待审核"
                  : item.applyState == 1
                  ? "审核通过"
                  : item.applyState == 2
                  ? "审核不通过"
                  : "";
              return item;
            });
            this.totalSize = res.data.count;
          }
        });
    },
    // 查询
    searchData() {
      this.getTableData(this.pageDatalist);
    },
    // 改变状审核态
    changeState(row) {
      if(this.applyStateValue){
        this.title='审核状态';
        this.centerDialogVisible=true;
        this.applyStates=row.applyState;
        this.stateForm={...row}; 
      }else{
        this.$message({showClose: true,message: '你操作过于频繁，请稍后尝试',type: 'warning'});
      }
    },
    // 改变财务状态
    changeFinanceState(row){
      if(this.financeStateValue){
        this.title='财务状态';
        this.centerDialogVisible=true;
        this.financeStates=row.financeState;
        this.stateForm={...row}; 
      }else{
        this.$message({showClose: true,message: '你操作过于频繁，请稍后尝试',type: 'warning'});
      }
    },
    //更改状态
    confirmSetState(){
      let paramsData={},url='';
      if(this.title==='审核状态'){//更改审核状态
        this.applyStateValue=false;
        const {projectId,id,applyState} = this.stateForm;
        if(this.applyStates!=applyState){
          paramsData={applyState,projectId,id};
          url='/operations/editProjectProfitApplyApplyState';
        }
      }else{//更改财务状态
        this.financeStateValue=false;
        const {projectId,id,financeState} = this.stateForm;
        if(this.financeStates!=financeState){
          paramsData={financeState,projectId,id};
          url='/operations/editProjectProfitApplyFinanceState';
        }
      };
      this.$axios.get(url,{params:paramsData})
       .then(res=>{
         if(res.data.state==1){
           this.getTableData(this.pageDatalist);
           this.$message({showClose: true,message:res.data.msg,type: "success"});
         }else{
           if(res.data.msg=='unkwon'){
              this.$message({showClose: true,message:'处理失败!',type: "error"});
           }else{
              this.$message({showClose: true,message:res.data.msg,type: "error"});
           }
         };
         this.centerDialogVisible=false;
      });
      setTimeout(()=>{
        this.applyStateValue=true;
        this.financeStateValue=true;
      },5000)
    },
    // 时间转换
    getTime(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "-" +
          (this.getMonth() + 1) +
          "-" +
          +this.getDate() +
          "" +
          " " +
          this.getHours() +
          ":" +
          this.getMinutes() +
          ":" +
          this.getSeconds()
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10,20,40,80]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalSize"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props:['totalSize','getMethods'],
  data() {
    return {
      currentPage: 1,//当前页,
      pageSize:20//当前条数
    };
  },
  created(){
    this.getMethods({pageSize:this.pageSize,pageNum:this.currentPage});
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getMethods({pageSize:this.pageSize,pageNum:this.currentPage})
    },
    handleCurrentChange(val) {
      this.currentPage =val;
      this.getMethods({pageSize:this.pageSize,pageNum:this.currentPage})
    }
  }
};
</script>

<style>

</style>